import { RedocStandalone } from 'redoc';

import './App.css';

function App() {
  return (
    <div className="App">
      <RedocStandalone specUrl="/openapi.yaml" />
    </div>
  );
}

export default App;
